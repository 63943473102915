@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a.navbar-brand img {
    width: 185px;
}
a.nav-link img {
    width: 39px;
}
.nav-link{
    color: #3D3C3A;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
margin: 0 1rem !important;
padding: 5px 20px !important;
}

a.nav-link.active{
    color: #3B42C4 !important;
    border-radius: 105px;
    background: rgba(59, 66, 196, 0.12) !important;
    text-decoration: none;
    padding: 5px 20px !important;
}
.back-btn-admin {
    color: #474747 !important;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #D3D3D3;
    padding: 8px 29px !important;
}



.loging-card{
    border-radius: 42px;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: #FAF9F7;
    padding: 3rem 4rem;
  
}
.loging-card h1{
    color: #1A1A1A;
    font-family: 'Poppins';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}
.loging-card p{
    color: #3D3C3A;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    
}
.input-filed{
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    padding: 1rem 1rem;
    width: 400px;
    color: #6F6F6F;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

}
.sign-in-btn{
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-radius: 180px;
    background: #3B42C4;
    border: 1px solid  #3B42C4;
    padding: 1rem 1rem;
    width: 400px;
}
.forget{
    color: #312F2F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;

}

.heading h1{
    color: #1A1A1A;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
}
.input-filed-profile {
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    width: 100%;
    padding: 0.7rem 2rem;
    outline: none;
}

label.po-ab-label {
    position: absolute;
    top: -29%;
    left: 3%;
    background: #ffff;
    color: #3B42C4;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 0px 9px;
    z-index: 111;
}
.heading-profile{
    margin-bottom: 3rem;
}
.heading-profile h1{
    color: #1A1A1A;
font-family: 'Poppins';
font-size: 30px;
font-style: normal;
font-weight: 700;
margin-top: 2rem;
margin-bottom: 4rem;
}
.profile-card{
    border-radius: 20px;
    background: #FFF;
    padding: 3rem 3rem;
    position: relative;
}

.profile-page{
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: #FAF9F7;
  
  }

  .heightcontrol{
    min-height: 50vw;
    height: 100%;
  }

.set-power a{
    color: #3B42C4;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    padding: 0 1rem;

}
.no-conversation-found{
  font-size: revert;
    color: #474747;
    padding: 28px;
    margin-top: 226px;
    text-align: center;

}
.text-center-invoice{
  text-align: center;
}

.text-center-invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* You can adjust the height as needed */
}

.save-changes{
    border-radius: 180px;
    background: #3B42C4;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding: 10px 40px;
    text-decoration: none;
}

.subscribe-kwikbot{
    border-radius: 180px;
    background: #3B42C4;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding: 10px 40px;
    text-decoration: none;
    display: block;  
}
.text-right{
    text-align: right;
}

.cancel{
    color: #8F8F8F;
text-align: center;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
text-decoration: none;
margin-right: 1rem;
}

img.prifle-icon-top {
    position: absolute;
    top: -8%;
    width: 70px;
}
.from-admin-pr{
    margin-top: 1rem;
}



/* CHANGE PASSWORD  */

.heading-profile h1 span{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
}
.heading-profile h1 span i {
    padding-right: 10px;
}
.profile-card h5{
    color: #000;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 2rem;
}
.text-login{
    color: #3D3C3A;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
}
.blue-text-b{
    color: #3B42C4;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.line-throght{
    color: #000;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 600;
text-decoration: line-through;
}
.heading-profile ul{
    list-style-type: none;
    padding: 0;
    margin-top: 1.5rem;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 75px;
}

.heading-profile ul li{
    display: inline-block;
    margin-right: 1.5rem;
    color: #3D3C3A;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 38px; /* 211.111% */
}

.li-active{
    color: #000;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 800 !important;
line-height: 38px; /* 211.111% */
border-bottom: 3px solid  #3B42C4;
}
.heading-profile h2{
    color: #1A1A1A;
font-family: 'Poppins';
font-size: 30px;
font-style: normal;
font-weight: 700;
margin-top: 3rem;
}
.form-check-label{
    color: #6F6F6F;
text-align: center;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;

}
.heading-payment h3{
    color: #1A1A1A;
font-family: 'Poppins';
font-size: 30px;
font-style: normal;
text-align: center!important;
font-weight: 700;
line-height: 68px; /* 226.667% */
}
.byline h5{
    color: #000;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 211.111% */
}
span.blue-badge {
    background-color: #3B42C4;
    color: #FFFFFFFF;
    padding: 5px 10px;
    border-radius: 50%;
}
img.arrow-image {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-50%);
    width: 12px;
}
.credite-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
}

.creditecard-image img {
    width: 30px;
    margin-right: 6px;
}
.radio-btn label{
    color: #6F6F6F;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-left: 7px;
}

input[type="radio"] {
    accent-color: #3B42C4;
}
.radio-btn {
    display: flex;
    align-items: center;
}
.main-card-stm {
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    background: #FFF;
}

.payment-card {
    border-bottom: 1px solid #E6E6E6;
}
.padding-05{
    padding: 0.7rem;
}
img.card-image-nu {
    position: absolute;
    top: 37%;
    right: 1%;
    transform: translate(-61%);
    width: 19px;
}
.security-text{
    color: #6F6F6F;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}
img.security {
    width: 13px;
    margin-right: 10px;
}

p.security-text {
    display: flex;
    align-items: center;
}
.text-normal{
    color: #3D3C3A;
text-align: center;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 500;
}

.blue-text-normal{
    color: #3B42C4 !important;
    font-family: 'Poppins';
    font-size: 22px !important;
    font-style: normal;
    font-weight: 600 ;
}
.profile-card h6{
    color: #000;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 600;
}

.confirm-paybtn{
    color: #FFF;
text-align: center;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 700;
text-decoration: none;
display: block;
border-radius: 180px;
background: #3B42C4;
padding: 10px 20px;
margin-top: 2rem;

}

.terms-text-pro{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 13px;
font-style: normal;
font-weight: 500;
margin-top: 1rem;
}

a.terms-link{
    color: #3B42C4;
}

.hr-line{
    background: #ECECEC;
    height: 1px;
    margin-bottom: 0.7rem;
}
.confirm-message {
    width: 405px;
    padding: 4rem 3rem;
    background-image: url('/public/images//confirm/confirm-meassge-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 5rem;
}

section.confirm-message-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-blur {
  backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
}


.confirm-message h3{
    color: #FFF;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;


text-align: center;
margin-top: 2rem;

}
.confirm-message p{
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.go-back{
    margin-top: 1.5rem;
    text-align: center;
}
.confirm-message .go-back a{
    color: #3B42C4;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    border-radius: 180px;
    background: #FFF;
    padding: 10px 20px;
   
}

.automatic-text{
    color: #D47070;
text-align: center;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 500;
}

.profile-card p{
    color: #3D3C3A;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
margin: 0;

}

.canbtn-s {
padding: 10px 30px;
color: #3B42C4 !important;
border-radius: 105px;
 background: rgba(59, 66, 196, 0.12) !important;
 margin-right: none !important;
}
.canbtn-s:hover{
    background: #3B42C4 !important;
    color: #FFF !important;
    border-radius: 105px;
    border: none;
}

.sm-gr-fnt{
    color: #6F6F6F;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 237.5% */  
}

.bld-byline{
    color: #000;
font-family: 'Poppins';
font-size: 25px;
font-style: normal;
font-weight: 600;
margin-bottom: 1rem;

}
.sub-textbld{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px !important;
font-style: normal;
font-weight: 600;
opacity: 0.8;
margin-bottom: 1rem !important;

}
.line-sm{
    color: #1A1A1A !important;
font-family: 'Poppins';
font-size: 16px !important;
font-style: normal;
font-weight: 400 !important;
margin-bottom: 1rem !important;
}

ul.cancel-renewal-ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
}

ul.cancel-renewal-ul li {
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 38px; /* 237.5% */
opacity: 0.9;
}

input.larger {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #E6E6E6 !important;
    background: #FFF;
    opacity: 0.8;
  }
  .some-tap-line{
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.7rem !important;

  }

  .textarea-cancel-renewal{
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    width: 100%;
    margin-bottom: 2.3rem;
    height: 5rem;
  }

  .blue-andbldtext{
    color: #3B42C4 !important;
font-family: 'Poppins';
font-size: 16px !important;
font-style: normal;
font-weight: 600 !important;
  }

  input.company-are{
    border-radius: 5px;
background: #3B42C4 !important;
width: 20px;
height: 20px;
margin-right:20px;
  }
  tr.light-blue th{ 
    background: #F0F0FF !important;
    padding: 10px 28px !important;
    color: #000;
font-family: 'Poppins';
font-size: 15px;
font-style: normal;

  }
  td {
    padding: 10px 28px !important;
    color: #6F6F6F !important;
font-family: 'Poppins';
font-size: 15px;
font-style: normal;
font-weight: 500;
/* text-align: center; */

}
tr.skyblue-light td{
    background: #F8FBFF !important;
}
 
  .box-border-left{
    border-radius: 20px 0px 0px 0px !important;
    padding-bottom: 3rem !important;
  }

  .box-border-right{
    border-radius: 0px 20px 0px 0px !important;
  }

  .download-history{
    border-radius: 16px;
background: #E7E8F8;
color: #3B42C4;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
padding: 10px 20px;
text-decoration: none;
  }
  .download-history img{
    margin-right: 10px;
  }



  /* CONTENTHISTORY   */
  .content-history-card {
    border-radius: 20px;
    background: #FFF;
    padding: 1rem 0.4rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .content-textb h5{
    color: #282734;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
  }
  .content-textb  p{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
  }
  .content-edit-history{
    background-color: #E7E8F8;
    padding: 8px 16px;
    border-radius: 50%;
    position: relative;
    color: #3B42C4 !important;
  }

  .content-edit-history::after {
    content: "";
    background-color: #E7E8F8;
    clip-path: polygon(0 1%, 51% 100%, 100% 0);
    position: absolute;
    left: 50%;
    bottom: -36%;
    height: 12px;
    width: 23px;
    transform: translate(-50%, -50%);
}
.text-graybtn{
    color: #6F6F6F !important;
    padding: 8px 16px;
}

.content-edit-box-card{
    border-radius: 20px;
background: #FFF;
margin-bottom: 1.5rem;
padding: 2rem 2rem 3rem;
}

.yes-btn-delete{
    display: block;
    width: 100%;
    padding: 20px 20px;
    border-radius: 0px 0px 20px 0px;
    background: #3B42C4;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 190% */
    border: none;
    outline: none;
}

.no-btn-delete{
    display: block;
    width: 100%;
    padding: 20px 20px;
    border-radius: 0px 0px 0px 20px;
    background: #F3F3F3;    
    color: #6F6F6F;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 190% */
    border: none;
    outline: none;
}
.modal-content{
    border-radius: 20px !important;
}
.text-for-delete{
    padding: 2rem 3rem;
}
.blue-text-delelet-page{
    color: #3B42C4 !important;
}
a.active.pos-abso-top {
    position: absolute;
    padding: 5px 18px;
    top: -16%;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.coversation-heading{
    color:#FFFFFF ;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 190% */
border-radius: 20px 0px 0px 0px;
background: #3B42C4;
padding: 1rem 2rem;
height: 5rem;
}
.conversation-chat-heading{
    border-radius: 0px 20px 0px 0px;
    background: #3B42C4;
padding: 1rem 2rem;
height: 5rem;
}

.chat-bot{
  text-align-last: right;
  max-width: 366px;
  float: right;
}
.chat-visitor {
  text-align-last: left;
  max-width: 366px;
  /* float: left; */
}

.conversation-chat-heading h4{
  color: #FFFFFF;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
margin: 0;
}

.conversation-chat-heading p{
    color: rgba(255, 255, 255, 0.80);
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 500;
margin: 0;

}


.first-conversation-list {
    width: 33%;
}
.conversation-chat {
    width: 67%;
    background: #ffff;
}

.name-date-conversation h6{
    color:#6f6f6f;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
margin: 0;
}
.name-date-conversation p{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
margin: 0;
}
.date-conversation p{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}

.padding-namedate.d-flex.justify-content-between {
    padding: 1rem 2rem;
    border-bottom: 1px solid #E7E7E7;
    background-color: #FFFFFFFF;
}
.chat-content-box p{
    color: #383838;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
/* line-height: 22px; 137.5% */
margin: 0;
}
.chat-content-box span{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 500;
/* line-height: 24px; 171.429% */
}

.chat-content-box {
    border-radius: 30px;
    background: #FAF9F7;
    padding: 1.5rem 3rem;
    max-width: 365px;
}

.chat-name {
    padding: 1rem 2rem;
}
.chat-name-bot{
    margin-bottom: 10px;
    padding-left: 35%;
}
.right-side {
  float: right;
}
.chat-name h6{
    color: #282734;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
/* line-height: 24px; 150% */
}

.ai-chatvisitor{
    margin-bottom: 10px;
    text-align: right;
}
.policy h2 {
    color: #1A1A1A;
font-family: 'Poppins';
font-size: 30px;
font-style: normal;
font-weight: 700;
margin: 1.5rem 0;

}

.policy h6{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
}

.policy h4{
    color: #282734;
font-family: 'Poppins';
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
margin: 1.5rem 0;
}

.policy p,.policy ol li{
    color: #6F6F6F;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
}
.resenotpbutton{
        text-align: center;
       margin-right: 20px;
       padding-left: 269px;

}
/* .custom-resetlink {
    margin-left: 170px;
} */



.faqs p {
    color: #3D3C3A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    /* 158.333% */
}

.faqs ul {
    list-style-type: none;
}

.faqs ul li {
    color: #1A1A1A;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    padding: 1rem;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: #FAF9F7;
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  a{
    text-decoration: none !important;
  }
  
  .brand-logo-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  
  .from-sauperabmin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
    
  }
  .input-soild{
    border: none;
    border-bottom: 1px solid #999;
    outline: none;
    padding: 10px 0;
    font-size: 1.2rem;
    color: #333;
    background: transparent;
    transition: .3s;
    width: 100%;
    margin: 20px 0;
    height: calc(1.9em + 1.1rem + 2px) !important;
    
  }
  
  .login-btn-superadmin{
    display: block !important;
    width: 100% !important;
    padding: 1rem 0 !important;
    margin-top: 2rem !important;
    text-align: center !important;
    color: #fff !important;
    background-color: #3b42c4 !important;
    border-color: #3b42c4 !important;
  
  }
  .forgot-password{
    font-size: 18px !important;
  }
  
  .modal-title {
    width: 100%;
  }
  
  .avter-img{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
  .bg-blue{
    background-color: #2b2b2b !important;
  }
  a.navbar-brand.superadmin,a.nav-link.superadmin{
    color: #FFFFFF !important;
  }
  
  .sidebar{
    background-color: #2b2b2b !important;
    padding: 1rem 0;
    height: 100vh;
  } 
  .sidebar .left-bar{
  list-style-type: none;
  }
  .sidebar .left-bar li{
    padding: 0 0rem;
      margin: 1rem 0rem;
  }
  
  button.toggle-button{
    right: auto;
    left: 10px;
    top: 25px;
    color: white;
    font-size: 25px;
  }
  .sidebar li a.inactive {
    color: #FFFFFF !important;
    padding: 1rem 1rem;
    border-radius: 4px;
    display: block;
    /* text-align: center; */
    margin: 10px 0;
    transition: ease-out 0.3s;
  }
  
  .sidebar li a.inactive:hover {
    background: #ffffff3b;
    color: #FFFFFF !important;
  }
  
  .active-inactive li a{
   border: 1px solid #3b42c4 !important;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    color: #3b42c4;
  }
  ul.active-inactive {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }
  .page-link{
    color: #3b42c4 !important;
  }
  
  .bgactive{
    background-color: #3b42c4 !important;
    color: #fff !important;
  }
  
  .add-user{
    background-color: #3b42c4 !important;
    color: #fff !important;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    text-decoration: none !important;
  }
  
  .table-res {
    width: 100%;
  }
  
  td a.edit {
    color: black;
    margin: 0 11px;
    font-size: 20px;
  }
  
  .modeladd span {
    display: block;
    width: 40px;
    height: 40px;
    background: gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
  }
  button.btn-close {
    display: none;
  }
  .modeladd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .modeline::after{
    content: '';
    position: absolute;
    top: 29%;
    left: -396px;
    width: 426px;
    height: 100%;
    background: rgba(0,0,0,0.5);
    height: 1px;
  
  }
  
  input.input-model,select.input-model.form-select {
    width: 100%;
    /* height: 3rem; */
    border: 1px solid #cccc;
    padding: 11px;
    background-color: transparent;
  }
  
  .input-topposition{
    position: relative;
  }
  label.toplabel {
    /* position: absolute;
    top: -15px;
    left: 12px;
    background: white; */
    padding: 4px;
  }
  
  input#huey{
    width: 20px;
  }
  
  /* SEARCH BAR CSS */
  
  .search-bar-container {
    display: flex;
    align-items: center;
  }
  span.circle-dummy {
    border: 1px solid;
    padding: 5px 7px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    width: 100%;
  }
  
  input.date-input {
    height: 3rem;
    border: 1px solid #cccc;
    padding: 10px 10px;

  }

  .h-100px{
    width: 120px;
  }
  
  .search-button {
    padding: 10px 15px;
    background-color: #3b42c4 !important;
    color: #fff;
    border: none;
    border-radius: 0 0px 0px 0;
    cursor: pointer;
    font-size: 16px;
  }
  
  .search-button:hover {
    background-color: #3b42c4 !important;
  }

  .dropdown-toggle::after{
    display: none !important;
  }
  
  /* side bar new  */
  
  .content {
    transition: margin-right 0.3s; /* Smooth transition for margin change */
  }
  
  .sidebar-open {
    margin-right: 0;
  }
  
  .sidebar-closed {
    margin-right: -250px; /* Sidebar width */
  }
  
  .toggle-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    color: #6a1b9a;
    cursor: pointer;
    z-index: 1000;
  }
  .sidebar.open{
    width: 250px;
  }
  .sidebar.closed{
    width: 0px;
  }
  
  .right-content {
    transition: margin-right 0.3s; /* Smooth transition for margin change */
  }
  
  .sidebar-open .right-content {
    margin-left: 250px; 
  }
  
  .sidebar-closed .right-content {
    margin-right: 0;
  }
  
  .admin-panel {
    display: flex;
  }
  .right-content{
    padding: 40px;
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
  }

  .right-content::-webkit-scrollbar {
    width: 6px;
}


  
  .actived{
    color: #FFFFFF !important;
    background: #ffffff3b;
    /* text-align: center; */
    display: block;
    padding: 1rem 1rem;
    border-radius: 4px;
    margin: 1rem 0;
    
  }
  
  
  /* styles.css */
  
  
  .progressBarFill {
    height: 10px;
    width: 100%;
    background-color: #3b42c4 !important;
    border-radius: 5px;
    transition: width 0.3s;
  }
  
  
  .progressBar {
    height: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
  }
  
  .progressBarStep {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
  }
  .modelhead{
    padding: 1rem 2rem;
  }
  .setpmodel{
    position: absolute;
    bottom: -25px;
    font-size: 20px;
    left: 0;
  }
  
  .stepCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e9ecef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    /* margin-right: 10px; */
    padding: 10px;
  }
  
  .stepCircle.active {
    background-color: #3b42c4 !important;
    color: #fff;
    width: 40px;
    height: 40px;
  }
  .modal-header {
    padding: 2rem 1rem !important;
  }
  .superadminNavbar {
   
      padding-left: 1196px;
  }

  a.nav-link img {
    width: 62px;
}

th{text-align: center !important;}

.custom-list {
  max-height: 500px; /* Set the maximum height for the list */
  overflow-y: auto; /* Add vertical scrollbar when needed */
  border: 1px solid #ccc; /* Add a border to the list */
  border-radius: 4px; /* Add rounded corners */
}

.custom-list .list-group-item {
  cursor: pointer; /* Change cursor to pointer on hover */
}
.center-container-home {
  justify-content: center;
  text-align: center;

}
.submitpublicpage{
  justify-content: center;
  text-align: center;

}

.custom-list .list-group-item:hover {
  background-color: #3b42c4!important;
  color: white; /* Change background color on hover */
}

.custom-list .active {
  background-color: #3b42c4!important;
  color: white; /* Add a background color to the active item */
}

  
.custom-table {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 100%; /* Optionally limit the table width */
}

/* Style table header (thead) */
.custom-table thead {
  background-color: #f5f5f5;
}

/* Style table rows based on sender (bot or visitor) */
.bot-message {
  background-color: #e5f7ff; /* Customize background color for bot messages */
}

.visitor-message {
  background-color: #f0f0f0; /* Customize background color for visitor messages */
}

.email-content-h{height: 1.5rem; padding-top: 1px;}
.email-content-h2{height: 3rem; padding-top: 1px;}
.top12admin{top: -12% !important;}
.message-text{color: red;}
.search-icon-topbar {
  position: absolute;
  top: 20%;
  left: 0px;
  left: 9px;
  opacity: 0.8;
}

.modal-backdrop.show{background-color: #0000004d !important; }
.active>.page-link, .page-link.active{ background-color: #3b42c4 !important;
  color: #fff !important;
}


/* Apply styles to the table container */
.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}

/* Style the table headers */
.table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;

}

/* Style the table rows */



.p-5.content {
  height: 100vh;
  overflow-y: scroll;
}

.p-5.content::-webkit-scrollbar {
  width: 8px;
}

.chat-scroll{
  height: 60vh;
  overflow-y: scroll;
}

.chat-scroll::-webkit-scrollbar {
  width: 8px;
}
.text-area-heightfix{
  height: 80px !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu{
  left: auto !important;
  right: 0 !important;
}
.loginpaddingleft{
  padding-left: 39px;
}
.sptoptextarea{
  top: -14% !important;
}

.view-more-link,
.view-less-link {
  text-decoration: none;
  cursor: pointer;
  color: #3b42c4; /* Link color */
  font-weight: bold;
  transition: color 0.3s, border-bottom 0.3s;
  display: inline-block;
}

.view-more-link::after,
.view-less-link::after {
 
  display: inline-block;
  width: 0;
  overflow: hidden;
  vertical-align: bottom;
  transition: width 0.3s;
}

.view-more-link:hover,
.view-less-link:hover {
  color: #3b42c4; /* Hover color */
}

.view-more-link.active::after,
.view-less-link.active::after {
  width: 1em; /* Adjust as needed for your design */
}

.custom-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 50px;
  cursor: pointer;
  color: #333; /* Color of the close button */
  padding: 0;
}

.custom-close-button:hover {
  color: #ff0000; /* Color when hovering over the close button */
}

.padding-namedate.d-flex.justify-content-between.highlighted-item {
  color: black!important;
  
}
.padding-namedate.d-flex.justify-content-between.highlighted-item .name-date-conversation h6{
  color: black!important;
  font-weight: 700;

}
 
.padding-namedate.d-flex.justify-content-between.highlighted-item .date-conversation p{
  color: black!important;
}

.loader-book{

margin-top: 200px;
text-align: center;
font-size: medium;
}